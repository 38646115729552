import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import 'tom-select/dist/css/tom-select.bootstrap5.css'

// Connects to data-controller='multiple-select'
export default class extends Controller {
  static targets = ['select']

  connect() {
    new TomSelect(this.selectTarget, {
      create: false
    })
  }
}
